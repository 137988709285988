import React from "react";
import Artykul from "../components/artykul";
import { graphql } from "gatsby";
import { GatsbyImage } from "gatsby-plugin-image";
import obrazekArtykulu from "../images/zlecenia-cykliczne-questy.png";
import Lightbox from "../components/lightBox";
import ResponsiveEmbed from "react-responsive-embed";
import { Link } from "gatsby";
import Container from "../components/container";
import { LiteYouTubeEmbed } from "react-lite-youtube-embed";
import * as styles from "./index.module.css";


export const query = graphql`
  {
    zdjecie1: file(
      relativePath: { eq: "questy-kartoteki-produktow-serwisowanych.jpg" }
    ) {
      childImageSharp {
        gatsbyImageData(layout: FULL_WIDTH)
      }
    }
    zdjecie2: file(relativePath: { eq: "karta-zlecenia-serwis.jpg" }) {
      childImageSharp {
        gatsbyImageData(layout: FULL_WIDTH)
      }
    }
    zdjecie3: file(relativePath: { eq: "historia-kontrahenci.jpg" }) {
      childImageSharp {
        gatsbyImageData(layout: FULL_WIDTH)
      }
    }
    zdjecie4: file(relativePath: { eq: "skrot-dodaj-zlecenie-cykliczne.jpg" }) {
      childImageSharp {
        gatsbyImageData(layout: FULL_WIDTH)
      }
    }
    zdjecie5: file(relativePath: { eq: "zlecenia-timeline.jpg" }) {
      childImageSharp {
        gatsbyImageData(layout: FULL_WIDTH)
      }
    }
    zdjecie6: file(relativePath: { eq: "zlecenia-lokalizacja-mapa.jpg" }) {
      childImageSharp {
        gatsbyImageData(layout: FULL_WIDTH)
      }
    }
    zdjecie7: file(relativePath: { eq: "tablica-kanban-przeplyw-zlecen.jpg" }) {
      childImageSharp {
        gatsbyImageData(layout: FULL_WIDTH)
      }
    }
    zdjecie8: file(
      relativePath: { eq: "automatyczne-powiadomienia-o-zleceniu.jpg" }
    ) {
      childImageSharp {
        gatsbyImageData(layout: FULL_WIDTH)
      }
    }
  }
`;

const Zleceniacykliczne = ({ data }) => {
  return (
    <Artykul
      title="Zlecenia cykliczne - jak nimi zarządzać?"
      articleImage={obrazekArtykulu}
      articleImageAlt="Zlecenia cykliczne - jak nimi zarządzać?"
      metaTitle="Zlecenia cykliczne - jak nimi zarządzać?"
      metaDescription="Jak skutecznie zarządzać zleceniami cyklicznymi? ✅ Jak automatycznie wysyłać klientom przypomnienia SMS o przeglądach? •  Obejrzyj video"
    >
      <p>
        Wykonywanie przeglądów cyklicznych może wynikać z warunków umowy z
        kontrahentem. Jako firma serwisująca jesteście zobowiązani do wykonania
        usługi np. przeglądu w określonym czasie.{" "}
        <strong>Niewywiązanie się z kontraktu może skończyć się karą.</strong>
      </p>
      <p>
        Natomiast jeżeli prowadzisz serwis i{" "}
        <strong>
          zależy Ci, aby klient cyklicznie korzystał z usług Waszej firmy
        </strong>{" "}
        - zadbaj o&nbsp;to. W jaki sposób? Przypominaj im o możliwości wykonania
        usługi w odpowiednim czasie. To wcale nie musi być trudne!
      </p>
      <div className="text-block">
        <br />
        <LiteYouTubeEmbed
          poster="maxresdefault"
          id="TjlbbeCC6Xo"
          title="Zlecenia cykliczne: jak nimi zarządzać w serwisie?"
        />
      </div>
      <p>
        Najczęściej, mówiąc o cyklicznej usłudze w obszarze serwisu, nasi
        kontrahenci mają na myśli przeglądy. Wyróżnia się dwa główne typy:
      </p>
      <p>
        <ul>
          <li>
            <strong>dotyczące maszyn i urządzeń</strong> (serwisy kotłów,
            klimatyzacji, maszyn rolniczych, ekspresów do kawy,
          </li>
          <li>
            <strong>
              przeglądy techniczne budynków lub systemów zabezpieczeń.
            </strong>{" "}
          </li>
        </ul>
      </p>
      <p>
        Obsługę zleceń cyklicznych wspierają systemy do zarządzania serwisem. W
        przypadku serwisu maszyn, bądź urządzeń, kluczowe jest przechowywanie
        informacji o serwisowanych produktach. Możesz to robić w papierowym
        notatniku lub w excelu, ale prędzej czy później zapanuje w tym bałagan
        lub pojawią się problemy z aktualnością i współdzieleniem informacji w
        firmie.
      </p>
      <p>
        {" "}
        <strong>Z pomocą przychodzą systemy do obsługi zleceń.</strong>
      </p>
      <section
      style={{
        backgroundColor: "rgb(244, 244, 244, 0.75)",
        color: "rgb(48, 48, 48)",
        marginTop: 30,
        marginBottom: 30
      }}
    >
      <div className="container container--text"
      >
        <h3 style={{
          paddingTop: 30,
          textAlign: "center",
        }}>Chcesz skutecznie zarządzać zleceniami cyklicznymi w Twojej firmie?</h3>
        <p style={{
          textAlign: "center",
        }}
          >Napisz do nas, aby zyskać dostęp do bezpłatnej 30-dniowej wersji systemu

</p>

      <div
            style={{
              textAlign: "center",
              margin: "20px",
              padding: "10px",
            }}
          >
            <Link className={styles.startNow} to="/kontakt/">
              Chcę wypróbować i sprawniej zarządzać zleceniami cyklicznymi
            </Link>
          </div>
          </div>
    </section>
      <h2
        style={{
          paddingTop: 20,
          paddingBottom: 20,
        }}
      >
        Jak zarządzać bazą maszyn i urządzeń z wykorzystaniem takiego
        rozwiązania?
      </h2>
      <p>
        System kataloguje dane, aby w łatwy sposób je odnaleźć. W zakładce
        “Produkty serwisowane” albo “Baza urządzeń” (nazewnictwo dowolne)
        znajduje się lista serwisowanych przez Twoją firmę maszyn.
      </p>
      <p>
        <strong>Każda maszyna ma przypisany indywidualny numer seryjny</strong>{" "}
        oraz przypisana jest do konkretnego kontrahenta lub adresu. Klikając w
        numer można przejść do szczegółowej karty produktu serwisowanego. Co
        można w niej zobaczyć? Wszystkie informacje jakie potrzebuje Twoja
        firma:{" "}
      </p>
      <p>
        <ul>
          <li>podstawowe: nazwa, numer seryjny, przypisany kontrahent,</li>
          <li>
            dowolne atrybuty np. data instalacji, specyfikacja techniczna,
            miejsce na dodatkowe uwagi, zdjęcia i inne,
          </li>
          <li>
            historię wykonanych i zaplanowanych zleceń, czyli całą historię
            napraw maszyny (to informacje, które mogą skrócić czas realizacji
            kolejnego zlecenia).
          </li>
        </ul>
      </p>
      <p>
        Taka lista atrybutów jest przykładowa,{" "}
        <strong>kartę produktu można dostosować do potrzeb Waszej firmy</strong>
        . Ważne, aby przechowywać tylko istotne informacje, aby nie wprowadzić
        dodatkowego zamieszania nieczytelną kartą. Jakie atrybuty możecie
        samodzielnie stworzyć? Pole typu:
      </p>
      <p>
        <ul>
          <li>
            data -{" "}
            <strong>
              np. data gwarancji, data przeglądu, data instalacji,
            </strong>
          </li>
          <li>
            plik -{" "}
            <strong>
              miejsce na podpięcie plików np. karty techniczne, specyfikacje
              maszyny, zdjecia,
            </strong>
          </li>
          <li>
            pola tekstowe - dowolne pole na{" "}
            <strong>dodatkowe cechy maszyny,</strong>{" "}
          </li>
          <li>
            pole typu lista wyboru - jeżeli zmienne są zamkniętą listą to pole
            sprawdza się najbardziej,{" "}
          </li>
          <li>
            pole typu liczba - <strong>np. moc urządzenia.</strong>
          </li>
        </ul>
      </p>
      <Lightbox
        style={{
          maxWidth: 800,
          margin: "0 auto",
        }}
        images={[data.zdjecie1]}
        alts={["Baza urządzeń serwisowanych w programie do serwisu"]}
      />
      <h2
        style={{
          paddingTop: 20,
          paddingBottom: 20,
        }}
      >
        Szybkie wyszukiwanie informacji
      </h2>
      <p>
        Zadaniem systemu do obsługi zleceń jest oszczędzenie czasu Twojego i
        Twoich pracowników. Jak często szukasz informacji o klientach,
        zleceniach lub maszynach? Możesz ten czas zaoszczędzić i spożytkować
        znacznie lepiej.
      </p>
      <p>System do obsługi serwisu pomoże Ci szybko i sprawnie wyszukać:</p>
      <p>
        <ul>
          <li>kontrahenta,</li>
          <li>zlecenie,</li>
          <li>produkt serwisowany (maszynę lub urządzenie).</li>
        </ul>
      </p>
      <p>
        Kryteria po jakich możesz wyszukiwać te obiekty są bardzo szerokie.{" "}
        <strong>
          Oprócz podstawowych pól w systemie, masz możliwość dodawania własnych
        </strong>
        , a następnie wyszukiwać konkretne informacje w oparciu o te cechy
      </p>
      <h2
        style={{
          paddingTop: 20,
          paddingBottom: 20,
        }}
      >
        Jakie dane przechowuje system do zleceń?{" "}
      </h2>
      <p>Systemy do serwisu przechowują uporządkowane bazy danych np.:</p>
      <p>
        <ul>
          <li>maszyn i urządzeń,</li>
          <li>zleceń,</li>
          <li>klientów,</li>
          <li>dokumentów,</li>
          <li>produktów i części, czyli magazyn.</li>
        </ul>
      </p>
      <p>
        O bazie urządzeń napisaliśmy już dużo. Przejdźmy do kolejnych dwóch
        istotnych miejsc, czyli{" "}
        <strong>bazy zleceń i&nbsp;bazy kontrahentów</strong>.
      </p>
      <h3
        style={{
          paddingTop: 20,
          paddingBottom: 20,
        }}
      >
        Komplet informacji o wykonanych zleceniach
      </h3>
      <p>
        <strong>Baza zleceń</strong> to uporządkowana lista wszystkich zleceń,
        wykonanych i zaplanowanych. Podobnie jak w produktach serwisowanych -
        karta zlecenia zawiera wszystkie kluczowe informacje.
      </p>
      <p>
        <strong>
          Historia wykonanych zleceń to prawdziwa kopalnia wiedzy.
        </strong>{" "}
        Wystarczy kliknąć w numer takiego zlecenia, by sprawdzić:
      </p>
      <p>
        <ul>
          <li>kto był odpowiedzialny za jego realizację,</li>
          <li>czego dotyczyło zlecenie,</li>
          <li>co zostało wykonane,</li>
          <li>jakie części zostały zużyte,</li>
          <li>ile czasu zajęła realizacja zlecenia, </li>
          <li>gdzie była realizacja,</li>
          <li>czy zlecenie zostało rozliczone,</li>
          <li>i inne - to zależy od tego jakich danych potrzebujecie.</li>
        </ul>
      </p>
      <Lightbox
        style={{
          maxWidth: 800,
          margin: "0 auto",
        }}
        images={[data.zdjecie2]}
        alts={["Historia wykonanych zlece cyklicznych w programie do serwisu"]}
      />
      <h3
        style={{
          paddingTop: 20,
          paddingBottom: 20,
        }}
      >
        Pełna historia współpracy z kontrahentami
      </h3>
      <p>
        <strong>Baza kontrahentów</strong> to także lista wszystkich
        kontrahentów. Kontrahentów możesz dowolnie segmentować np.: wg typu:
        potencjalni, klienci, partnerzy, dostawcy lub wg cech: branża, wielkość
        firmy i inne.
      </p>
      <p>W karcie każdego kontrahenta znajdują się m.in.:</p>
      <p>
        <ul>
          <li>
            wykonane zadania i zlecenia (tworzą pełną historię Waszej
            współpracy),
          </li>
          <li>wystawione dokumenty (oferty, zamówienia, faktury),</li>
          <li>zaplanowane zadania i zlecenia, </li>
          <li>dane kontaktowe do osób odpowiedzialnych za kontakt z Wami,</li>
          <li>dane firmowe,</li>
          <li>informacje o serwisowanej maszynie.</li>
        </ul>
      </p>
      <Lightbox
        style={{
          maxWidth: 800,
          margin: "0 auto",
        }}
        images={[data.zdjecie3]}
        alts={["Historia współpracy z kontrahentem"]}
      />
      <p>
        Skoro wiesz już jakie dane przechowuje system do zleceń i dlaczego warto
        w ten sposób nimi zarządzać, możemy przejść do drugiej części, czyli
        planowania zleceń cyklicznych.
      </p>
      <h2
        style={{
          paddingTop: 20,
          paddingBottom: 20,
        }}
      >
        Jak zapanować nad zleceniami cyklicznymi?{" "}
      </h2>
      <p>
        System do serwisu obsługuje wszystkie typy zleceń. Domyślnie ustawionych
        jest kilka typów, ale możecie stworzyć ich tyle ile potrzebujecie. Po
        zalogowaniu do systemu, wyświetli się <strong>Pulpit pracy</strong>. To
        miejsce, które zawiera najważniejsze dla danego profilu informacje.
      </p>
      <p>
        Koordynator zleceń powinien mieć na swoim pulpicie ustawiony skrót,
        niezbędny do szybkiego tworzenia zleceń. Jest to przycisk, który
        wywołuje okno z danymi do uzupełnienia, aby następnie utworzyć zlecenie.
      </p>
      <p>
        Pola, które są widoczne na formatce tworzenia zleceń również zależą od
        Waszych potrzeb. System, aby oszczędzić Twój czas:
      </p>
      <p>
        <ul>
          <li>
            podpowiada nazwę maszyny albo klienta po wpisaniu pierwszych liter,
          </li>
          <li>
            umożliwia stworzenie jednocześnie: karty kontrahenta, karty zlecenia
            i karty produktu serwisowanego.{" "}
          </li>
        </ul>
      </p>
      <p>
        Wracając do głównego wątku - zleceń cyklicznych. Co należy uzupełnić
        dodając zlecenie cykliczne?
      </p>
      <p>
        <ol>
          <li>Zaznaczyć pole “Utwórz wiele”.</li>
          <li>Wybrać z listy częstotliwość powtórzeń np. co rok.</li>
          <li>Uzupełnić termin zakończenia.</li>
        </ol>
      </p>
      <p>
        System poniżej wyświetla liczbę procesów, które utworzą się w tej
        konfiguracji. Co to oznacza? Właśnie zaplanowałeś 5 kolejnych zleceń
        typu przegląd dla kontrahenta Edellwise.
      </p>
      <Lightbox
        style={{
          maxWidth: 300,
          margin: "0 auto",
        }}
        images={[data.zdjecie4]}
        alts={["Dodawanie zlecenia cyklicznego"]}
      />
      <p>Jak działa zarządzanie zleceniami cyklicznymi w systemie?</p>
      <p>
        <ol>
          <li>
            Dodajesz jedno zlecenie - system tworzy ich tyle ile ustaliłeś.
          </li>
          <li>
            ystem przypomni klientom np. za rok o konieczności wykonania
            przeglądu.
          </li>
          <li>
            Oprócz tego masz dostęp do listy zleceń, gdzie możesz na bieżąco
            obserwować zaplanowane zlecenia dla kontrahentów.
          </li>
        </ol>
      </p>
      <h2
        style={{
          paddingTop: 20,
          paddingBottom: 20,
        }}
      >
        Jakie płyną z tego korzyści?
      </h2>
      <p>
        <ol>
          <li>Oszczędność czasu.</li>
          <li>
            Automatyczne przypomnienia - pewność, że kontrahenci otrzymają
            informacje o zleceniu.
          </li>
          <li>Poprawa jakości obsługi klienta.</li>
          <li>
            Więcej zleceń - klienci nie przechodzą do konkurencji, która
            pamiętała o nich.
          </li>
          <li>Porządek w danych kontrahentów, zleceń i produktów.</li>
        </ol>
      </p>
      <h2
        style={{
          paddingTop: 20,
          paddingBottom: 20,
        }}
      >
        Jakie inne funkcje systemu do zleceń pomagają w zarządzaniu zleceniami
        cyklicznymi?{" "}
      </h2>
      <h3
        style={{
          paddingTop: 20,
          paddingBottom: 20,
        }}
      >
        Kalendarz zleceń
      </h3>
      <p>
        Kalendarz zleceń pozwala wyświetlić listę pracowników i oś czasu
        prezentującą przypisane do nich zlecenia. W ten sposób, możesz szybko
        zweryfikować, który serwisant ma okno czasowe na nowe zlecenie.
      </p>
      <Lightbox
        style={{
          maxWidth: 800,
          margin: "0 auto",
        }}
        images={[data.zdjecie5]}
        alts={["kalendarz zleceń"]}
      />
      <h3
        style={{
          paddingTop: 20,
          paddingBottom: 20,
        }}
      >
        Mapy i lokalizacja
      </h3>
      <p>
        Mapy dostępne bezpośrednio w systemie wyświetlają adresy zleceń oraz
        lokalizację serwisanta (jeżeli wyraził On na to zgodę w aplikacji
        mobilnej). Kluczowe jest poprawne uzupełnienie pola adres podczas
        dodawania zlecenia, aby system poprawnie wyświetlał zlecenia na mapie.
        Dzięki temu możesz optymalnie planować zadania dla serwisantów.
      </p>
      <p>Mapy umożliwiają:</p>
      <p>
        <ul>
          <li>sprawne ustawianie kolejki zleceń w pobliżu,</li>
          <li>
            przypisywanie zleceń do osoby, która może najszybciej dotrzeć na
            miejsce awarii.
          </li>
        </ul>
      </p>
      <Lightbox
        style={{
          maxWidth: 800,
          margin: "0 auto",
        }}
        images={[data.zdjecie6]}
        alts={["wyświetlanie zleceń cyklicznych na mapie"]}
      />
      <h3
        style={{
          paddingTop: 20,
          paddingBottom: 20,
        }}
      >
        Tablica zleceń
      </h3>
      <p>
        Wizualna prezentacja danych ma kluczowe znaczenie dla odbiorcy. Łatwiej
        nam odczytać komunikat, który jest napisany poprawnie i w atrakcyjnej
        formie. Podobnie jest z listą zleceń. Ułożenie zleceń jako kafelków w
        konkretnych etapach procesu (nowe, w realizacji i zakończone), pozwoli
        Ci bardzo szybko zorientować się w sytuacji. Monitorowanie realizacji
        zleceń staje się proste.
      </p>
      <Lightbox
        style={{
          maxWidth: 800,
          margin: "0 auto",
        }}
        images={[data.zdjecie7]}
        alts={["przeływ zleceń - tablica kanban"]}
      />
      <h3
        style={{
          paddingTop: 20,
          paddingBottom: 20,
        }}
      >
        Automatyzacja powiadomień SMS i e-mail
      </h3>
      <p>
        System do zleceń na podstawie stworzonych przez Ciebie zleceń
        cyklicznych może wysyłać powiadomienia SMS lub e-mail Twoim klientom
        kilka dni przed koniecznością wykonania np. przeglądu.
      </p>
      <Lightbox
        style={{
          maxWidth: 800,
          margin: "0 auto",
        }}
        images={[data.zdjecie8]}
        alts={["automatyczne powiadomienia o zleceniach"]}
      />
      <p>
      <section
      style={{
        backgroundColor: "rgb(244, 244, 244, 0.75)",
        color: "rgb(48, 48, 48)",
        marginTop: 30,
        marginBottom: 30
      }}
    >
      <div className="container container--text"
      >
        <h3 style={{
          paddingTop: 30,
          textAlign: "center",
        }}>Chcesz skutecznie zarządzać zleceniami cyklicznymi w Twojej firmie?</h3>
        <p style={{
          textAlign: "center",
        }}
          >Napisz do nas, aby zyskać dostęp do bezpłatnej 30-dniowej wersji systemu

</p>

      <div
            style={{
              textAlign: "center",
              margin: "20px",
              padding: "10px",
            }}
          >
            <Link className={styles.startNow} to="/kontakt/">
              Chcę wypróbować i sprawniej zarządzać zleceniami cyklicznymi
            </Link>
          </div>
          </div>
    </section>
        Zachęcamy do zapoznania się z innymi materiałami, które przygotowaliśmy:{" "}
      </p>
      <p>
        <ul>
          <li>
            <Link to="/baza-urzadzen">
              Jak zarządzać historią urządzeń serwisowanych?
            </Link>
          </li>
          <li>
            <Link to="/7-wyzwan-w-serwisie">
              7 problemów serwisów - jak je rozwiązać?
            </Link>
          </li>
        </ul>
      </p>
    </Artykul>
  );
};

export default Zleceniacykliczne;
